import { useCallback } from "react";

const useHandleBackLiff = () => {
  const handleBackLiff = useCallback(() => {
    const url = window.location.origin;
    const token = localStorage.getItem("__token__")
    console.warn("USER_LOGOUT", url, token);
    localStorage.clear();    
    window.open(
      `${process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}/v2/logout?client_id=${
        process.env.REACT_APP_OTP_CLIENT_ID
      }&returnTo=${encodeURIComponent(url)}?check=true&direct_logout=true`,
      "_self"
    );
  }, []);

  return handleBackLiff;
};

export default useHandleBackLiff;