import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { setUserInfo } from "store/user/userSlice";
import "./PopupInfo.css";
import { useDispatch } from "react-redux";

const PopupExpired = (props) => {
  const { open } = props;
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    return;
  };

  const handleBackLiff = () => {
    const lineAppUrl = `http://line.me/ti/p/${process.env.REACT_APP_LINE_BOT_ID}`;
    window.location.href = lineAppUrl;
  };

  const handleLogin = async () => {
    const url = window.location.origin;
    dispatch(setUserInfo());
    // dispatch(setError());
    localStorage.clear();
    window.open(
      `${process.env.REACT_APP_LOGIN_UPBOND_DOMAIN}/v2/logout?client_id=${
        process.env.REACT_APP_OTP_CLIENT_ID
      }&returnTo=${encodeURIComponent(url)}&direct_logout=true`,
      "_self"
    );
  };

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full md:max-w-mobile m-4 transform overflow-hidden rounded-2xlleft align-middle shadow-xl text-left transition-all mb-[30vh]">
                  <div className="flex flex-col px-6 py-8 rounded-2xl bg-[#D9D9D9] text-center">
                    <div className="ryogothic text-2xl font-light leading-6 text-primary-100 w-full text-center mb-16">
                      セッションタイムアウト
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-2">
                      セッションの有効期限が
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-2">
                      切れています。
                    </div>
                    <div className="ryogothic text-base font-light leading-6 text-primary-100 w-full mb-32">
                      再度ログインしてください。
                    </div>
                    <div className="flex justify-center gap-4">
                      <button
                        className={`button w-[150px] text-lg`}
                        onClick={handleBackLiff}
                      >
                        アプリ閉じる
                      </button>
                      <button
                        className={`button bg-[#EC4C4C] w-[150px] text-lg`}
                        onClick={handleLogin}
                      >
                        ログイン
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default PopupExpired;
